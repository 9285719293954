import React, { forwardRef, useState } from 'react';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { FcCalendar } from 'react-icons/fc';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';

const DateSelector = ({ inputProps }) => {
  const {
    name,
    required,
    minLength,
    placeholder,
    icon,
    register,
    formatted_name,
    dateFormat = 'dd/MM/yyyy',
    disableFuture,
    disabled,
    maxDate,
    width,
  } = inputProps || {};

  const [startDate, setStartDate] = useState(new Date());

  const { setValue } = useFormContext();

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <InputGroup width={width}>
        <Input
          id={`${name}`}
          placeholder={placeholder}
          {...register(name, {
            required: required ? `${formatted_name} is required` : false,
            minLength: {
              value: minLength,
              message: `Minimum length should be ${minLength}`,
            },
          })}
          onClick={onClick}
          ref={ref}
          value={value}
          disabled={disabled}
        />
        <InputRightElement
          children={icon ? icon : <FcCalendar fontSize='1.2em' />}
        />
      </InputGroup>
    );
  });

  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => {
        setStartDate(date);
        setValue(name, dayjs(date).format('DD/MM/YYYY'));
        // setValue(name, date.toISOString());
      }}
      customInput={<CustomInput />}
      dateFormat={dateFormat}
      maxDate={disableFuture ? new Date() : maxDate}
    />
  );
};

export default DateSelector;
