import React, {useState} from "react";
import { Spinner } from "@chakra-ui/react";
import { FcCheckmark, FcHighPriority } from "react-icons/fc";
import Http from "../../../../utils/Http";
import OneFieldForm from "../../Forms";

const Email = ({ email, setEmail, methods, width, mc, endpoint }) => {
  const [disableButton, setDisableButton] = useState(false)

  const verifyEmail = async (formData) => {
    setEmail({ loading: true, verified: false, response: '' });

    const emailData = {
      'microchip_number': Number(mc.microchip_number),
      'email': formData.email.toLowerCase()
    }

      Http.post(`/voip/searchEmailNew.php`, emailData)
      .then((res) => {
        if (res.data.data.verified) {
          setEmail({ loading: false, verified: true, response: 'hello' });
        } else {
          setEmail({ loading: false, verified: false });
          setDisableButton(true)
        }
      })
      .catch((err) => {
        setEmail({ loading: false, verified: false, error: err });
      });
  };

  return (
    <OneFieldForm
      methods={methods}
      state={email}
      onSubmit={verifyEmail}
      name='email'
      label='Email'
      placeholder='Email'
      required
      component='inputWithIcon'
      disabled={email?.verified ? email?.verified : disableButton}
      type='email'
      width={width}
      icon={
        email.loading ? (
          <Spinner />
        ) : email.verified ? (
          <FcCheckmark />
        ) : email.verified === false ? (
          <FcHighPriority />
        ) : null
      }
    />
  );
};

export default Email;
