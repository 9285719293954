import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
  Text,
  InputRightElement,
  InputGroup,
  Textarea,
} from '@chakra-ui/react';
import { FcInfo } from 'react-icons/fc';
import DateSelector from './DatePicker';
import TimeSelector from './TimePicker';

const Field = (props) => {
  const {
    name,
    label,
    required,
    minLength,
    placeholder,
    help = '',
    component,
    icon,
    submitButton,
    disabled,
    width,
    disableFuture = false,
  } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const formatted_name = name?.replaceAll('_', ' ');

  const componentSwitcher = () => {
    switch (component) {
      case 'input':
        return (
          <Input
            id={`${name}`}
            placeholder={placeholder}
            {...register(name, {
              required: required ? `${formatted_name} is required` : false,
              minLength: {
                value: minLength,
                message: `Minimum length should be ${minLength}`,
              },
            })}
            disabled={disabled}
            width={width}
          />
        );

      case 'inputWithIcon':
        return (
          <Flex align='center' gap='2'>
            <InputGroup>
              <Input
                id={`${name}`}
                placeholder={placeholder}
                {...register(name, {
                  required: required ? `${formatted_name} is required` : false,
                  minLength: {
                    value: minLength,
                    message: `Minimum length should be ${minLength}`,
                  },
                })}
                disabled={disabled}
                width={width}
              />
              <InputRightElement children={icon} />
            </InputGroup>
            {submitButton && submitButton}
          </Flex> 
        );

      case 'textarea':
        return (
          <Textarea
            id={`${name}`}
            placeholder={placeholder}
            {...register(name, {
              required: required ? `${formatted_name} is required` : false,
              minLength: {
                value: minLength,
                message: `minimum length should be ${minLength}`,
              },
            })}
            disabled={disabled}
            width={width}
          />
        );

      case 'date':
        return (
          <DateSelector
            disableFuture={disableFuture}
            inputProps={{ ...props, formatted_name, register, width }}
          />
        );
      case 'time':
        return (
          <TimeSelector
            inputProps={{ ...props, formatted_name, register, width }}
          />
        );
      default:
    }
  };

  return (
    <>
      <FormControl isInvalid={errors[name]}>
        <FormLabel htmlFor={`${name}`}>{label}</FormLabel>

        {componentSwitcher()}

        {!errors[name] ? (
          <FormHelperText>
            {help && (
              <Flex align='center' gap='2'>
                <FcInfo />
                <Text>{help}</Text>
              </Flex>
            )}
          </FormHelperText>
        ) : (
          <FormErrorMessage>
            {errors[name] && errors[name].message}
          </FormErrorMessage>
        )}
      </FormControl>
    </>
  );
};

export default Field;
