import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Flex,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import PageContainer from '../../components/PageContainer';
// import LostTab from '../../components/Lost';
import RequestDetailsTab from '../../components/RequestDetails';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = () => {
  // let query = useQuery();

  // const newQuery = query.get("service")

  // const [activeTab, setActiveTab] = useState(newQuery || 'request-details');
  const [isLargerThan1000px] = useMediaQuery('(min-width: 1000px)');

  // const buttonProps = {
  //   colorScheme: 'blue',
  //   width: { base: '100%' },
  //   height: { base: '2.5em', md: '3em' },
  //   fontSize: { base: '1.5em', md: '2em' },
  // };

  return (
    <PageContainer>
      <Flex
        direction='column'
        align='center'
        justify='center'
        width={isLargerThan1000px ? '60%' : '100%'}
      >
        <ButtonGroup gap='4' width='100%'>
          {/* <Button
            {...buttonProps}
            onClick={() => activeTab !== 'lost' && setActiveTab('lost')}
            backgroundColor={activeTab === 'lost' && 'darkblue'}
            _hover={activeTab === 'lost' && {bg:'darkblue'}}
            
          >
            Lost
          </Button> */}
          {/* <Button
            {...buttonProps}
            onClick={() => activeTab !== 'request-details' && setActiveTab('request-details')}
            backgroundColor={activeTab === 'request-details' && 'darkblue'}
            _hover={activeTab === 'request-details' && {bg:'darkblue'}}
          >
            Request Details
          </Button> */}
        </ButtonGroup>
      </Flex>
      {/* {activeTab === 'lost' && <LostTab />} */}
      {/* {activeTab === 'request-details' && <RequestDetailsTab />} */}
      <RequestDetailsTab />
    </PageContainer>
  );
};

export default Home;
