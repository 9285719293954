import { Box, Flex, Image } from '@chakra-ui/react';
import React from 'react';

const Header = () => {
  return (
    <Flex justify='center' width='100%' bg='#00AFFF' height='70px'>
      <Flex direction='column' align='center' justify='center'>
        <Box bg='white' height='70px' px={{ base: '1em', md: '2em' }}>
          <Image width='200px' src='logo.png' alt='Smart Trace Logo' />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Header;
