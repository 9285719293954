import React, { forwardRef, useState } from 'react';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { FcClock } from 'react-icons/fc';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';

const TimeSelector = ({ inputProps }) => {
  const {
    name,
    required,
    minLength,
    placeholder,
    icon,
    register,
    formatted_name,
    timeFormat = 'h:mm aa',
    disabled,
    width,
  } = inputProps || {};

  const [startDate, setStartDate] = useState(new Date());

  const { setValue } = useFormContext();

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <InputGroup width={width}>
        <Input
          id={`${name}`}
          placeholder={placeholder}
          {...register(name, {
            required: required ? `${formatted_name} is required` : false,
            minLength: {
              value: minLength,
              message: `Minimum length should be ${minLength}`,
            },
          })}
          onClick={onClick}
          ref={ref}
          value={value}
          disabled={disabled}
        />
        <InputRightElement
          children={icon ? icon : <FcClock fontSize='1.2em' />}
        />
      </InputGroup>
    );
  });

  return (
    <DatePicker
      selected={startDate}
      onChange={(time) => {
        setStartDate(time);
        setValue(name, dayjs(time).format('HH:mm'));
        // setValue(name, time);
      }}
      customInput={<CustomInput />}
      dateFormat={timeFormat}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption='Time'
    />
  );
};

export default TimeSelector;
