import React, { useEffect, useState } from 'react';
import { Button, Divider, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import Field from '../../components/Fields';
import { useForm, FormProvider } from 'react-hook-form';
import MicrochipNumber from '../Fields/OneFieldForms/MicrochipNumber';
import Email from '../Fields/OneFieldForms/Email';
import Postcode from '../Fields/OneFieldForms/Postcode';

const LostTab = () => {
  const [isLargerThan1000px] = useMediaQuery('(min-width: 1000px)');

  const [microchip, setMicrochip] = useState({
    loading: false,
    verified: null,
  });

  const microchipNumber = microchip.formData

  const [email, setEmail] = useState({ loading: false, verified: null });
  const [postcode, setPostcode] = useState({ loading: false, verified: null });
  const [disableMainForm, setDisableMainForm] = useState(true);

  const methods = useForm();
  const { isSubmitting } = methods.formState;
  const { setValue, reset } = methods;

  function onSubmit(values) {
    return new Promise((resolve) => {
      setTimeout(() => {
        alert(JSON.stringify(values, null, 2));
        resolve();
      }, 3000);
    });
  }

  const {
    handleSubmit: handleSubmit1,
    reset: reset1,
    register: register1,
    getValues: getValues1,
    setValues: setValues1,
    formState: { errors: errors1 },
  } = useForm({
    mode: 'onSubmit',
  });

  const {
    handleSubmit: handleSubmit2,
    reset: reset2,
    register: register2,
    getValues: getValues2,
    setValues: setValues2,
    formState: { errors: errors2 },
  } = useForm({
    mode: 'onSubmit',
  });

  const {
    handleSubmit: handleSubmit3,
    reset: reset3,
    register: register3,
    getValues: getValues3,
    setValues: setValues3,
    formState: { errors: errors3 },
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    //Add values to main form once verified
    if (microchip?.verified)
      setValue('microchip_number', getValues1('microchip_number'));
    if (email?.verified) setValue('email', getValues2('email'));
    if (postcode?.verified) setValue('postcode', getValues3('postcode'));

    if ((microchip?.verified, email?.verified, postcode?.verified))
      setDisableMainForm(false);
    else setDisableMainForm(true);
  }, [
    microchip?.verified,
    email?.verified,
    postcode?.verified,
    getValues1,
    getValues2,
    getValues3,
    setValue,
  ]);

  const resetForms = () => {
    reset();
    reset1();
    reset2();
    reset3();
    setMicrochip({
      loading: false,
      verified: null,
    });
    setEmail({
      loading: false,
      verified: null,
    });
    setPostcode({
      loading: false,
      verified: null,
    });
    setDisableMainForm(true);
  };
  //   const verifyMicrochip = async () => {
  //     setMicrochip({ loading: true, verified: false });
  //     // Http.post(`products-check-sku/${getValues('microchip_number')}`)
  //     Http.post(`/blogs`, {
  //       category: 0,
  //       archive: 0,
  //       pagination: 50,
  //     })
  //       .then((res) => {
  //         const result = { data: { data: { verified: true } } };

  //         if (result.data.data.verified) {
  //           setMicrochip({ loading: false, verified: true });
  //         } else {
  //           setMicrochip({ loading: false, verified: false });
  //         }
  //         // if (res.data.data.verified) {
  //         //   setMicrochip({ loading: false, verified: true });
  //         // } else {
  //         //   setMicrochip({ loading: false, verified: false });
  //         // }
  //       })
  //       .catch((err) => {
  //         setMicrochip({ loading: false, verified: false, error: err });
  //       });
  //   };

  return (
    <Flex
      direction='column'
      gap='5'
      width={isLargerThan1000px ? '60%' : '100%'}
    >
      <Flex direction='column' gap='2'>
        <Text as='h2' fontSize='1.5em' fontWeight='bold' mt='1em'>
          Report pet as missing
        </Text>
        <Text>
          We are sorry to hear that your pet is missing. Use the form below to
          report it.
        </Text>
      </Flex>
      <Divider borderBottom='1px solid #00AFFF' />
      <Flex direction='column' gap='5'>
        <Text as='h3' fontSize='1.5em' fontWeight='bold' mt='1em'>
          Verification Details
        </Text>
        <MicrochipNumber
          microchip={microchip}
          setMicrochip={setMicrochip}
          methods={{
            handleSubmit: handleSubmit1,
            reset: reset1,
            register: register1,
            getValues: getValues1,
            setValues: setValues1,
            errors: errors1,
          }}
          //
        />
        {microchip?.verified && (
          <Email
            mc={microchipNumber}
            email={email}
            setEmail={setEmail}
            methods={{
              handleSubmit: handleSubmit2,
              reset: reset2,
              register: register2,
              getValues: getValues2,
              setValues: setValues2,
              errors: errors2,
            }}
          />
        )}
        { microchip?.verified && email?.verified === false && email.loading === false &&
            <>
              <Text as='h2' fontSize='1.5em' fontWeight='bold' mt='1em'>
                Email Not Verified
              </Text>
              <Text>
              Unfortunatly your email address and details have not been verified on our system.
                A support ticket has been rasied and a member of our team will contact you shortly.
              </Text>
            </>
          }
        {email?.verified && (
          <Postcode
            mc={microchipNumber}
            postcode={postcode}
            setPostcode={setPostcode}
            methods={{
              handleSubmit: handleSubmit3,
              reset: reset3,
              register: register3,
              getValues: getValues3,
              setValues: setValues3,
              errors: errors3,
            }}
          />
        )}
      </Flex>

      <Divider borderBottom='1px solid #00AFFF' />
      {((microchip?.verified && !email?.verified) ||
        (microchip?.verified && !postcode?.verified)) && (
        <Flex align='right' justify='right' gap='3' mb='2' width='100%'>
          <Button
            mt={4}
            bg='#3182CE'
            fontSize='1.3 em'
            colorScheme='blue'
            onClick={() => resetForms()}
            py={isLargerThan1000px ? '1.5em' : '1em'}
            width={isLargerThan1000px ? '20%' : '40%'}
          >
            Reset
          </Button>
        </Flex>
      )}

      {microchip?.verified && email?.verified && postcode?.verified && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Flex direction='column' gap='5'>
              <Text as='h3' fontSize='1.5em' fontWeight='bold' mt='1em'>
                Report Details
              </Text>
              <Field
                name='location'
                component='input'
                label='Location'
                placeholder='Location'
                required
                disabled={disableMainForm}
              />
              <Field
                name='date'
                component='date'
                label='Date'
                placeholder='Date'
                disabled={disableMainForm}
                disableFuture={true}
              />
              <Field
                name='time'
                component='time'
                label='Time'
                placeholder='Time'
                disabled={disableMainForm}
              />
              <Field
                name='notes'
                component='textarea'
                label='Notes'
                placeholder='Notes'
                disabled={disableMainForm}
              />
            </Flex>
            <Divider borderBottom='1px solid #00AFFF' />
            <Flex align='right' justify='right' gap='3' mb='2'>
              <Button
                mt={4}
                bg='#3182CE'
                fontSize='1.3em'
                colorScheme='3182CE'
                onClick={() => resetForms()}
                py={isLargerThan1000px ? '1.5em' : '1em'}
                width={isLargerThan1000px ? '20%' : '40%'}
              >
                Reset
              </Button>
              <Button
                mt={4}
                bg='#3182CE'
                fontSize='1.3em'
                colorScheme='3182CE'
                isLoading={isSubmitting}
                type='submit'
                py={isLargerThan1000px ? '1.5em' : '1em'}
                width={isLargerThan1000px ? '20%' : '40%'}
              >
                Report
              </Button>
            </Flex>
          </form>
        </FormProvider>
      )}
    </Flex>
  );
};

export default LostTab;
