import { Flex } from '@chakra-ui/react';
import React from 'react';
import Banner from '../Banner';
import Header from '../Header';
import PageContent from '../PageContent';

const PageContainer = ({ children }) => {
  return (
    <Flex width='100%' direction='column'>
      <Header />
      <Banner />
      <PageContent content={children} />
    </Flex>
  );
};

export default PageContainer;
