import React, { useState } from 'react'
import { Spinner } from "@chakra-ui/react";
import { FcCheckmark, FcHighPriority } from "react-icons/fc";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import Http from "../../../../utils/Http";

export default function ConfirmEmail({email, emailVerified, mc, confirmEmail, setConfirmEmail }) {

  const [input, setInput] = useState('')
  const [submitted, setSubmitted] = useState(false)
  // const [confirmEmail, setConfirmEmail] = useState({ loading: false, verified: null });

  const handleInputChange = (e) => setInput(e.target.value)

  const onSubmit = () => {
    setConfirmEmail({ loading: true, verified: false, response: '' });
    setSubmitted(true)

    const confirmEmailData = {
      'microchip_number': Number(mc.microchip_number),
      'email': email,
      'verified': emailVerified
    }

      Http.post(`/voip/submit.php`, confirmEmailData) 
      .then((res) => {
        if (res) {
          setConfirmEmail({ loading: false, verified: true, response: res.data });
        } else {
          setConfirmEmail({ loading: false, verified: false, response: 'email not confirmed' });
        }
      })
      .catch((err) => {
        setConfirmEmail({ loading: false, verified: false, error: err });
        console.log(err);
      });
  }

  const confirmed = input === email ? true : false

  // const isError = input === ''
  const icon = 
    confirmEmail?.loading ? (
      <Spinner />
    ) : input === email ? (
      <FcCheckmark />
    ) : input !== email && input.length > 0 ? (
      <FcHighPriority />
    ) : null


  return (
    <FormControl >
      <FormLabel>Confirm Email</FormLabel>

    <Flex align='center' gap='2' >
    <InputGroup>
      <Input 
        type='email' 
        value={input} 
        onChange={handleInputChange} 
        backgroundColor={'#F5F9FE'}
      />
      <InputRightElement children={icon} />
      </InputGroup>
       
      
    </Flex>
    <FormHelperText>
          Please Confirm your email address.
    </FormHelperText>
      <Button
        colorScheme={submitted ? 'green' : 'blue'}
        disabled={submitted || !confirmed }
        type='submit'
        onClick={onSubmit}
        marginTop='16px'
        width='100%'
      >
        {/* Submit */}
        {submitted ? 'Requested' : 'Request Details'}
        {/* {confirmEmail?.verified ? 'Requested' : 'Request Details'} */}
      </Button>
        
    </FormControl>
  )
}



// import React from "react";
// import { Spinner } from "@chakra-ui/react";
// import { FcCheckmark, FcHighPriority } from "react-icons/fc";
// import Http from "../../../../utils/Http";
// import OneFieldForm from "../../Forms";

// const ConfirmEmail = ({ confirmEmail, setConfirmEmail, verified, methods, width, mc, email }) => {
//   console.log(email, 'values');

//   const verifyConfirmEmail = async (formData) => {
//     setConfirmEmail('{ loading: true, verified: false }');

//     const confirmEmailData = {
//       'microchip_number': Number(mc.microchip_number),
//       'email': formData.confirm_email,
//       'verfied': verified
//     }

//       Http.post(`/voip/submit.php`, confirmEmailData) 
//       .then((res) => {
//         if (res.data.data) {
//           setConfirmEmail({ loading: false, verified: true });
//         } else {
//           setConfirmEmail({ loading: false, verified: false });
//         }
//         // console.log(res, 'here')
//       })
//       .catch((err) => {
//         setConfirmEmail({ loading: false, verified: false, error: err });
//       });
//   };

//   return (
//     <OneFieldForm
//       methods={methods}
//       state={confirmEmail}
//       onSubmit={verifyConfirmEmail} 
//       name='confirm_email'
//       label='Confirm Email'
//       placeholder='Email'
//       required
//       component='inputWithIcon'
//       disabled={confirmEmail?.verified}
//       type='email'
//       width={width}
//       help={
//         email !== confirmEmail && 'Emails do not match'
//       }
//       icon={
//         confirmEmail?.loading ? (
//           <Spinner />
//         ) : confirmEmail?.verified ? (
//           <FcCheckmark />
//         ) : confirmEmail?.verified === false ? (
//           <FcHighPriority />
//         ) : null
//       }
//     />
//   );
// };

// export default ConfirmEmail;
