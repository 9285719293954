import React from 'react';
import { Box } from '@chakra-ui/react';

const Banner = () => {
  return (
    <Box
      h='10px'
      // bg={{ base: 'orange', sm: 'blue', md: 'green', lg: '#2c3c49' }}
      bg='#2c3c49'
      width='100%'
      justify='center'
      p='0.2em'
    />
  );
};

export default Banner;
