import React, { useEffect } from "react";
import { Spinner } from "@chakra-ui/react";
import { FcCheckmark, FcHighPriority } from "react-icons/fc";
import Http from "../../../../utils/Http";
import OneFieldForm from "../../Forms";

const MicrochipNumber = ({ microchip, setMicrochip, methods, width }) => {
  const verifyMicrochip = async (formData) => {
    setMicrochip({ loading: true, verified: false, response: ''});

    Http.post(`/voip/searchMicrochipNew.php`, formData)
      .then((res) => {
        if (res.data.data.verified) {
          setMicrochip({ loading: false, verified: true, formData, response: res.data.data.response });
        } else {
          setMicrochip({ loading: false, verified: false, response: res.data.data.response })
        }
      })
      .catch((err) => {
        setMicrochip({ loading: false, verified: false, error: err });
      });
  };

  return (
    <OneFieldForm
      methods={methods}
      state={microchip}
      onSubmit={verifyMicrochip}
      name='microchip_number'
      label='Microchip Number'
      placeholder='Microchip Number'
      minLength={15}
      maxLength={15}
      required
      width={width}
      help={
        !microchip?.verified &&
        "Enter the microchip number associated with your pet"
      }
      component='inputWithIcon'
      disabled={microchip?.verified}
      icon={
        microchip.loading ? (
          <Spinner />
        ) : microchip.verified ? (
          <FcCheckmark />
        ) : microchip.verified === false ? (
          <FcHighPriority />
        ) : null
      }
    />
  );
};

export default MicrochipNumber;
