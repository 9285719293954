import React from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  FormErrorMessage,
  Input,
  InputRightElement,
  InputGroup,
  Text,
} from '@chakra-ui/react';
import { FcInfo } from 'react-icons/fc';

const OneFieldForm = ({
  methods,
  name,
  label,
  placeholder,
  minLength,
  maxLength,
  required,
  help,
  component,
  icon,
  state,
  onSubmit,
  disabled,
  type,
  width,
}) => {
  const { register, getValues, setValues, handleSubmit, watch, errors } =
    methods;

  const formatted_name = name?.replaceAll('_', ' ');

  const regExValidation = {
    email: {
      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      message: 'please enter a valid email',
    },
    postcode: {
      value: /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i,
      message: 'please enter a valid postcode',
    },
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={errors[name]}>
        <FormLabel htmlFor={`${name}`}>{label}</FormLabel>

        <Flex align='center' gap='2' width={width}>
          <InputGroup>
            <Input
              // textTransform={name === 'email' && 'lowercase'}
              id={`${name}`}
              placeholder={placeholder}
              maxLength={maxLength}
              {...register(name, {
                pattern: regExValidation[type] && regExValidation[type],
                required: required ? `${formatted_name} is required` : false,
                minLength: {
                  value: minLength,
                  message:
                    name === 'microchip_number'
                      ? 'Microchip number must be 15 characters'
                      : `Minimum length should be ${minLength} characters`,
                },
                maxLength: {
                  value: maxLength,
                  message: `maximum length should be ${maxLength} characters`,
                },
              })}
              disabled={disabled}
            />
            <InputRightElement children={icon} />
          </InputGroup>
          <Button
            colorScheme={state.verified && disabled ? 'green' : 'blue'}
            disabled={state.verified || disabled}
            type='submit'
          >
            {state.verified ? 'Verified' : 'Submit'}
          </Button>
        </Flex>

        {!errors[name] ? (
          <FormHelperText>
            {help && (
              <Flex align='center' gap='2'>
                <FcInfo />
                <Text>{help}</Text>
              </Flex>
            )}
          </FormHelperText>
        ) : (
          <FormErrorMessage>
            {errors[name] && errors[name].message}
          </FormErrorMessage>
        )}
      </FormControl>
    </form>
  );
};

export default OneFieldForm;
