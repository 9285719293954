import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

const PageContent = ({ content }) => {
  return (
    <Flex width='100%' justify='center' direction='column' align='center'>
      <Flex
        width='100%'
        px='15px'
        mx='40px'
        justify='center'
        direction='column'
      >
        {content}
      </Flex>
    </Flex>
  );
};

export default PageContent;
