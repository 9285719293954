import { Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import LostTab from './components/Lost';
import RequestDetailsTab from './components/RequestDetails'

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/service' element={<Home />} />
        <Route path='/?q=requestdetails' element={<RequestDetailsTab />} />
        {/* <Route path="about" element={<About />} /> */}
      </Routes>
    </div>
  );
}

export default App;
