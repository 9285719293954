import React from "react";
import { Spinner } from "@chakra-ui/react";
import { FcCheckmark, FcHighPriority } from "react-icons/fc";
import Http from "../../../../utils/Http";
import OneFieldForm from "../../Forms";

const Postcode = ({ postcode, setPostcode, methods, width, mc }) => {
  const verifyPostcode = async (formData) => {
    setPostcode({ loading: true, verified: false });

    const postcodeData = {
      'microchip_number': Number(mc.microchip_number),
      'postcode': formData.postcode
    }

    Http.post(`/voip/searchPostcode.php`, postcodeData)
      .then((res) => {
        if (res.data.data.verified) {
          setPostcode({ loading: false, verified: true });
        } else {
          setPostcode({ loading: false, verified: false });
        }
      })
      .catch((err) => {
        setPostcode({ loading: false, verified: false, error: err });
      });
  };

  return (
    <OneFieldForm
      methods={methods}
      state={postcode}
      onSubmit={verifyPostcode}
      name='postcode'
      label='Post Code'
      placeholder='Post Code'
      required
      help={!postcode?.verified && "Must be linked to pet owner account"}
      component='inputWithIcon'
      disabled={postcode?.verified}
      type='postcode'
      width={width}
      icon={
        postcode.loading ? (
          <Spinner />
        ) : postcode.verified ? (
          <FcCheckmark />
        ) : postcode.verified === false ? (
          <FcHighPriority />
        ) : null
      }
    />
  );
};

export default Postcode;
