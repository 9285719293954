import axios from 'axios';

const api = process.env.REACT_APP_API;

export const baseApi = api;

axios.defaults.baseURL = api;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

export default axios;
