import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import Field from '../../components/Fields';
import { useForm, FormProvider } from 'react-hook-form';
import MicrochipNumber from '../Fields/OneFieldForms/MicrochipNumber';
import Email from '../Fields/OneFieldForms/Email';
import ConfirmEmail from '../Fields/OneFieldForms/ConfirmEmail';

const RequestDetailsTab = () => {
  const [isLargerThan1000px] = useMediaQuery('(min-width: 1000px)');

  const [microchip, setMicrochip] = useState({
    loading: false,
    verified: null,
    response: ''
  });

  const microchipNumber = microchip.formData

  const [email, setEmail] = useState({ loading: false, verified: null, response: '' });

  const [confirmEmail, setConfirmEmail] = useState({ loading: false, verified: null });

  const [disableMainForm, setDisableMainForm] = useState(true);

  const methods = useForm();
  const { isSubmitting } = methods.formState;
  const { setValue, reset } = methods;

  function onSubmit(values) {
    return new Promise((resolve) => {
      setTimeout(() => {
        alert(JSON.stringify(values, null, 2));
        resolve();
      }, 3000);
    });
  }

  const {
    handleSubmit: handleSubmit1,
    reset: reset1,
    register: register1,
    getValues: getValues1,
    setValues: setValues1,
    formState: { errors: errors1 },
  } = useForm({
    mode: 'onSubmit',
  });

  const {
    handleSubmit: handleSubmit2,
    reset: reset2,
    register: register2,
    getValues: getValues2,
    setValues: setValues2,
    formState: { errors: errors2 },
  } = useForm({
    mode: 'onSubmit',
  });

  // const {
  //   // handleSubmit: handleSubmit3,
  //   // reset: reset3,
  //   // register: register3,
  //   getValues: getValues3,
  //   setValues: setValues3,
  //   // formState: { errors: errors3 },
  // } = useForm({
  //   mode: 'onSubmit',
  // });

  useEffect(() => {
    //Add values to main form once verified
    if (microchip?.verified)
      setValue('microchip_number', getValues1('microchip_number'));

    if (email?.verified) setValue('email', getValues2('email'));

    // if ((microchip?.verified, email?.verified))
    //   setDisableMainForm(false);

    // if (confirmEmail) setValue('confirm_email', getValues3('confirm_email'));

    if ((microchip?.verified, email?.verified, confirmEmail))
   
      setDisableMainForm(false);

    else setDisableMainForm(true);
  }, [
    microchip?.verified,
    email?.verified,
    confirmEmail,
    getValues1,
    getValues2,
    // getValues3,
    setValue,
  ]);

  const resetForms = () => {
    reset();
    reset1();
    reset2();
    setMicrochip({
      loading: false,
      verified: null,
    });
    setEmail({
      loading: false,
      verified: null,
    });
    setConfirmEmail({
      loading: false,
      verified: null,
    });
    setDisableMainForm(true);
  };

  return (
    <Flex
      direction='column'
      gap='5'
      width={isLargerThan1000px ? '60%' : '100%'}
    >
      <Flex direction='column' gap='2'>
        <Text as='h2' fontSize='1.5em' fontWeight='bold' mt='1em'>
          Request Ownership Details
        </Text>
        <Text>
          Complete the form below to request ownership details
        </Text>
      </Flex>
      <Divider borderBottom='1px solid #00AFFF' />
      <Flex direction='column' gap='5'>
        <Text as='h3' fontSize='1.5em' fontWeight='bold' mt='1em'>
          Verification Details
        </Text>

        <MicrochipNumber
          microchip={microchip}
          setMicrochip={setMicrochip}
          methods={{
            handleSubmit: handleSubmit1,
            reset: reset1,
            register: register1,
            getValues: getValues1,
            setValues: setValues1,
            errors: errors1,
          }}
          //
        />

        {!microchip?.verified && microchip?.response && (
          <>
            <Flex
              backgroundColor={'#F5F9FE'}
              width= '100%'
              padding= '8px'
              minHeight='40px'
              height='100%'
              borderRadius='8px'
            >
              <Text>
                {microchip.response}
              </Text>
            </Flex>
          </>
        )}

        {microchip?.verified && microchip?.response && (
          <>
            <Flex
              backgroundColor={'#F5F9FE'}
              width= '100%'
              padding= '8px'
              minHeight='40px'
              height='100%'
              borderRadius='8px'
            >
              <Text>
                {microchip.response}
              </Text>
            </Flex>
          </>
        )}

        {/* Keep incase we need to revert to sending an html response */}
        {/* {!microchip?.verified && microchip?.response && (
          <>
           <Flex
              backgroundColor={'#F5F9FE'}
              width= '100%'
              padding= '8px'
              minHeight='40px'
              height='100%'
              borderRadius='8px'
            >
              <Text>
                {microchip?.response.map(i => (
                  <Box 
                    dangerouslySetInnerHTML={ { __html: i } }
                    padding='8px'
                  >
                  </Box>
                ))}
              </Text>
            </Flex>
          </>
        )} */}

        {microchip?.verified && (
            <Email
              endpoint={'Valid'}
              mc={microchipNumber}
              email={email}
              setEmail={setEmail}
              methods={{
                handleSubmit: handleSubmit2,
                reset: reset2,
                register: register2,
                getValues: getValues2,
                setValues: setValues2,
                errors: errors2,
              }}
            />
        )}

          { microchip?.verified && email?.verified &&
            <>
              <Flex
                backgroundColor={'#F5F9FE'}
                width= '100%'
                padding= '8px'
                minHeight='40px'
                height='100%'
                borderRadius='8px'
                flexDirection={'column'}
              >
                <Text 
                  as='h2'  
                  fontSize='16px' 
                  fontWeight='bold' 
                >
                  Email Approved
                </Text>
                <Text
                  mt='16px'
                >
                  Your email is verified. You will receive owner details immediately upon submission
                </Text>
              </Flex>
              <ConfirmEmail
                mc={microchipNumber}
                emailVerified={email?.verified}
                confirmEmail={confirmEmail}
                setConfirmEmail={setConfirmEmail}
                email={getValues2('email')}
                methods={{
                  // handleSubmit: handleSubmit3,
                  // reset: reset3,
                  // register: register3,
                  // getValues: getValues3,
                  // setValues: setValues3,
                  // errors: errors3,
                }}
              />

            </>
            
          }

          {/* keep below for rendering response from server in future */}
          {/* {
            confirmEmail?.verified && 
            <Flex>
                  <Box 
                    dangerouslySetInnerHTML={ { __html: confirmEmail?.response } }
                    padding='8px'
                  >
                  </Box>
            </Flex>
          } */}


          { microchip?.verified && email?.verified === false && email.loading === false &&
            <>
              <Flex
                backgroundColor={'#F5F9FE'}
                width= '100%'
                padding= '8px'
                minHeight='40px'
                height='100%'
                borderRadius='8px'
                flexDirection={'column'}
              >
                <Text 
                  as='h2' 
                  fontSize='16px' 
                  fontWeight='bold' 
                >
                  Pending Email Verification
                </Text>
                <Text
                  mt='16px'
                >
                  Your email is not verified. You will receive owner details subject to verification (up to 48 hrs)
                </Text>
              </Flex>
              <ConfirmEmail
                mc={microchipNumber}
                emailVerified={false}
                confirmEmail={confirmEmail}
                setConfirmEmail={setConfirmEmail}
                email={getValues2('email')}
                methods={{
                  // handleSubmit: handleSubmit3,
                  // reset: reset3,
                  // register: register3,
                  // getValues: getValues3,
                  // setValues: setValues3,
                  // errors: errors3,
                }}
              />
            </>
          }

              {
                confirmEmail?.verified && confirmEmail?.response &&
                  <Text>
                  Thank you, your request has been received.
                  </Text>
               
              }
              {
              !confirmEmail?.verified && confirmEmail?.response &&
                  <Text>
                    There was an issue with your request, please try again or email us at 
                    <span>
                      <a color='blue' href='mailto:access@smarttrace.org.uk'>
                        {' access@smarttrace.org.uk'}
                      </a>
                    </span>
                  </Text>
              }
      </Flex>

      <Divider borderBottom='1px solid #00AFFF' />
      {((microchip?.verified && !email?.verified) &&
        <Flex align='right' justify='right' gap='3' mb='2' width='100%'>
          <Button
            mt={4}
            bg='#3182CE'
            fontSize='1.3 em'
            colorScheme='3182CE'
            onClick={() => resetForms()}
            py={isLargerThan1000px ? '1.5em' : '1em'}
            width={isLargerThan1000px ? '20%' : '40%'}
          >
            Reset
          </Button>
        </Flex>
      )}

      {microchip?.verified && email?.verified && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Flex align='center' justify='right' gap='3' mb='2'>
              <Button
                mt={4}
                bg='#3182CE'
                fontSize='1.3em'
                colorScheme='3182CE'
                onClick={() => resetForms()}
                py={isLargerThan1000px ? '1.5em' : '1em'}
                width={isLargerThan1000px ? '10%' : '40%'}
              >
                Reset
              </Button>
          
            </Flex>
          </form>
        </FormProvider>
      )}
    </Flex>
  );
};

export default RequestDetailsTab;
